import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('psi/vendor/listDealer', data)
}
//修改
export function getEdit(data) {
    return post('psi/vendor/editDealer', data)
}
//添加
export function getAdd(data) {
    return post('psi/vendor/addDealer', data)
}
//添加
export function getInfo(data) {
    return post('psi/vendor/dealerInfo', data)
}
// 结算
export function getOption(data) {
    return post('psi/vendor/paid', data)
}
export function getOption2(data) {
    return post('admin/contract/xiala', data)
}
//分类
export function getOption3(data) {
    return post('psi/classify/downward', data)
}






