<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <el-tabs>
        <el-tab-pane label="基础信息">
          <el-form :model="form" label-position="left" size="mini">
            <el-form-item
              label="供应商名称"
              :label-width="formLabelWidth"
              prop="dealer_name"
            >
              <el-input
                v-model="form.dealer_name"
                placeholder="供应商名称"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="供应商地址"
              :label-width="formLabelWidth"
              prop="dealer_site"
            >
              <el-input
                v-model="form.dealer_site"
                placeholder="供应商地址"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="联系人姓名"
              :label-width="formLabelWidth"
              prop="name"
            >
              <el-input v-model="form.name" placeholder="联系人姓名"></el-input>
            </el-form-item>
            <el-form-item
              label="联系方式"
              :label-width="formLabelWidth"
              prop="phone"
            >
              <el-input v-model="form.phone" placeholder="联系方式"></el-input>
            </el-form-item>
            <el-form-item
              label="供应商评分"
              :label-width="formLabelWidth"
              prop="score"
            >
              <el-input
                v-model="form.score"
                placeholder="供应商评分"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="选择信息">
          <el-form :model="form" label-position="left" size="mini">
            <el-form-item
              label="合同id"
              :label-width="formLabelWidth"
              prop="contract_id"
            >
              <el-select
                v-model="form.contract_id"
                style="width: 100%"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in options2"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="供应商分类"
              :label-width="formLabelWidth"
              prop="dealer_class"
            >
              <el-cascader
                :options="options3"
                :props="optionProps"
                v-model="form.dealer_class"
                filterable
                clearable
              >
              </el-cascader>
            </el-form-item>
            <el-form-item
              label="结算方式"
              :label-width="formLabelWidth"
              prop="clearing_id"
            >
              <el-select
                v-model="form.clearing_id"
                style="width: 100%"
                placeholder="请选择结算方式"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="关联任务" :label-width="formLabelWidth">
              <el-cascader
                placeholder="关联任务"
                :options="optionsTask"
                :props="optionPropsTask"
                v-model="form.task_id"
                filterable
              ></el-cascader>
            </el-form-item>

            <el-form-item
              label="税率%"
              :label-width="formLabelWidth"
              prop="tax_rate"
            >
              <el-slider v-model="form.tax_rate"></el-slider>
            </el-form-item>
            <el-form-item
              label="账期（天数）"
              :label-width="formLabelWidth"
              prop="payment"
            >
              <el-input-number
                v-model="form.payment"
                :min="0"
                label="账期（天数）"
              ></el-input-number>
            </el-form-item> </el-form
        ></el-tab-pane>
        <!-- <el-tab-pane label="合同文书">
          <div>
            <el-link type="primary" :href='form.contract_url'>下载文书</el-link>
          </div>
          <el-image
            v-for="(item, index) in info.img"
            :key="index"
            :src="item"
            width="200px"
            style="width: 300px"
          ></el-image>
        </el-tab-pane> -->
        <el-tab-pane label="修改图片">
          <el-upload
            :headers="token"
            accept=".jpg,.png"
            :on-remove="imgRemove"
            :limit="3"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess"
            name="image"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">上传商品图</el-button>
          </el-upload>
        </el-tab-pane>
        <el-tab-pane label="确认修改">
          <el-button @click="setAdd" type="primary" size="medium"
            >修改</el-button
          >
        </el-tab-pane>
      </el-tabs>
      <!-- 头 -->
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getEdit,
  getInfo,
  getOption,
  getOption2,
  getOption3,
} from "@/api/psi/dealer.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  name: "WriteArticle",
  data() {
    //这里存放数据
    return {
      optionPropsTask: {
        value: "id",
        label: "name",
        children: "son",
        disabled: "state",
      },
      optionsTask: [],
      options: [],
      options2: [],
      options3: [],
      optionProps: {
        value: "id",
        label: "name",
        children: "sub",
      },
      token: {
        "Accept-Token": window.sessionStorage.getItem("token"),
      },
      //总页数
      exp: {
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page: 1,
        list_rows: 4,
      },
      //导航
      nav: [],
      options: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: "一级",
        },
        {
          id: 2,
          name: "二级",
        },
      ],
      getNav: [],
      fileList: [],

      //即将删除的信息
      userdelinfo: {},
      info: {},
      // 添加信息表单
      form: {
        dealer_name: "",
        dealer_site: "",
        name: "",
        phone: "",
        contract_id: "",
        dealer_class: [],
        clearing_id: "",
        tax_rate: 0,
        payment: "",
        score: "",
        img: [],
        task_id: [],
      },
      //表单名称长度
      formLabelWidth: "120px",
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: "",
      //表格数据
      tableData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //移除图片的事件
    imgRemove(file, fileList) {
      console.log(fileList);
      this.form.img = [];
      fileList.forEach((item) => {
        if (item.response) {
          this.form.img.push(item.response.data.url);
        } else {
          this.form.img.push(item.url);
        }
      });
      console.log(this.form);
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
      console.log(response);
      this.form.img.push(response.data.url);
    },
    exceed() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
    clear() {
      this.form = {
        dealer_name: "",
        dealer_site: "",
        name: "",
        phone: "",
        contract_id: "",
        dealer_class: [],
        clearing_id: "",
        tax_rate: 0,
        payment: "",
      };
    },
    change(val) {
      console.log(val);
    },
    goBack() {
      this.$router.go(-1);
    },
    //搜索事件
    search(e) {
      console.log("搜索信息: " + e);
    },
    //添加事件--提交事件
    async setAdd(e) {
      console.log(this.form);
      this.form.id = this.info.id;

      let info = await getEdit(this.form);
      this.judge(info);
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        this.$router.push("/dealer");
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: "error",
        });
        return;
      }
    },
    async setOption() {
      let optionsTask = await this.$getOptionTask();
      this.optionsTask = optionsTask.data.data;
      let info = await getOption();
      let info2 = await getInfo({ id: this.$route.query.id });
      this.options = info.data.data;
      this.info = info2.data.data;
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.info[key];
      });
      this.info.img.forEach((item, index) => {
        let listInfo = { name: this.$route.query.name + index, url: item };
        this.fileList.push(listInfo);
      });
      let options = await getOption(this.getInfo);
      let options2 = await getOption2(this.getInfo);
      let options3 = await getOption3(this.getInfo);
      this.options = options.data.data;
      this.options2 = options2.data.data;
      this.options3 = options3.data.data;
      console.log(this.form);
    },

    //添加事件 退出弹窗
    userClose(e) {
      this.form = {};
      console.log("close");
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {
    this.setOption();
    this.getNav = this.$getNavn("/dealer");
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.tab-top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 10px;
}
.tab-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  margin-right: 10px;
}
.tab-info > span {
  display: flex;
  width: 80px;
}
.info-bt {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>